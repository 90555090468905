<template>
  <div style="margin: 2% 3% 0% 3%;">
    <v-card style="background-color: #e9e8e8; padding: 20px; width: 100%;">
      <div style="display: flex;">
        <div style="width: 70%;">
          <v-select v-model="input.localityIDS" :items="display.localityOptions" :loading="loader.selectLocalityIDAndLocalityName" :disabled="input.globalReportSelected" multiple chips item-title="localityName" item-value="localityID" append-inner-icon="mdi-store" label="Localidad" variant="solo-filled"></v-select>
        </div>
        <div style="width: 14%; margin-left: 1%;">
          <v-checkbox @click="selectAllLocality()" v-model="input.allLocalitySelected" color="info" label="Todas las localidades" hide-details></v-checkbox>
        </div>
        <div style="width: 9%; margin-left: 1%;">
          <v-checkbox @click="selectGlobalReport()" v-model="input.globalReportSelected" color="info" label="Global" hide-details></v-checkbox>
        </div>
        <div style="width: 9%; margin-left: 1%;">
          <v-switch v-model="display.currencySymbol" true-value="$" false-value="₡" :label="display.currencySymbol" hide-details color="success"></v-switch>
        </div>
      </div>
      <div style="display: flex;">
        <v-text-field v-model="display.startDateFormatted" @click="navigation.startDatePickerIsVisible = true" readonly label="Fecha inicial" append-inner-icon="mdi-calendar" variant="solo-filled" style="width: 49.5%; margin-right: 0.5%;"></v-text-field>
        <v-dialog v-model="navigation.startDatePickerIsVisible" width="auto">
          <v-card style="display: flex;">
            <v-date-picker v-model="input.startDate" hide-header color="primary" width="100%"></v-date-picker>
            <v-chip @click="input.startDate = new Date()" style="margin: 0% 5% 5% 5%; text-align: center; cursor: pointer;" color="primary" variant="flat">Hoy</v-chip>
          </v-card>
        </v-dialog>
        <v-text-field v-model="display.endDateFormatted" @click="navigation.endDatePickerIsVisible = true" readonly label="Fecha final" append-inner-icon="mdi-calendar" variant="solo-filled" style="width: 49.5%; margin-left: 0.5%;"></v-text-field>
        <v-dialog v-model="navigation.endDatePickerIsVisible" width="auto">
          <v-card>
            <v-date-picker v-model="input.endDate" hide-header color="primary" width="100%"></v-date-picker>
            <v-chip @click="input.endDate = new Date()" style="margin: 0% 5% 5% 5%; text-align: center; cursor: pointer;" color="primary" variant="flat">Hoy</v-chip>
          </v-card>
        </v-dialog>
      </div>
      <br><br>
      <div v-if="loader.selectInformation" style="text-align: center;">
        <v-progress-circular color="info" indeterminate></v-progress-circular>
      </div>
      <div v-else>
        <v-btn @click="selectInformation()" :disabled="loader.selectLocalityIDAndLocalityName || loader.selectInformation" dark block height="38" color="primary">
          <h3>BUSCAR</h3>
          <v-icon style="margin-left: 20px;" dark right>mdi-magnify</v-icon>
        </v-btn>
        <br>
        <v-btn @click="setDefaultValues()" :disabled="loader.selectLocalityIDAndLocalityName || loader.selectInformation" dark block height="38" color="#ffd978">
          <h3>LIMPIAR</h3>
          <v-icon style="margin-left: 20px;" dark right>mdi-backspace</v-icon>
        </v-btn>
      </div>
    </v-card>
    <div v-if="display.information">
      <br>
      <div v-if="input.globalReportSelected">
        <div style="padding: 30px; border-radius: 10px; width: 100%; background-color: #e9e8e8;">
          <v-data-table-virtual :headers="display.globalReportHeaders" :items="Object.values(display.globalInformation)">
            <template v-slot:item.localityName="{ item }">
              <div v-if="item.localityName == 'GLOBAL'">
                <p style="font-size: x-large; font-weight: bold;">TOTAL</p>
              </div>
              <p v-else style="font-size: large;">{{ item.localityName }}</p>
            </template>
            <template v-slot:item.billAmount="{ item }">
              <div v-if="item.localityName == 'GLOBAL'">
                <p style="font-size: x-large; font-weight: bold">{{ display.currencySymbol }} {{ ((Object.values(display.globalInformation).reduce((total, row) => total + row.billAmount, 0)) / navigation.dolarExchangeRate).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2}) }}</p>
              </div>
              <p v-else style="font-size: large;">{{ display.currencySymbol }} {{ (item.billAmount / navigation.dolarExchangeRate).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2}) }}</p>
            </template>
            <template v-slot:item.billAmountWithoutCost="{ item }">
              <div v-if="item.localityName == 'GLOBAL'">
                <p style="font-size: x-large; font-weight: bold">{{ display.currencySymbol }} {{ ((Object.values(display.globalInformation).reduce((total, row) => total + (row.billAmount - row.productCostAmount), 0)) / navigation.dolarExchangeRate).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2}) }}</p>
              </div>
              <p v-else style="font-size: large;">{{ display.currencySymbol }} {{ ((item.billAmount - item.productCostAmount) / navigation.dolarExchangeRate).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2}) }}</p>
            </template>
            <template v-slot:item.billDeductionAmount="{ item }">
              <div v-if="item.localityName == 'GLOBAL'">
                <p style="font-size: x-large; font-weight: bold">{{ display.currencySymbol }} {{ ((Object.values(display.globalInformation).reduce((total, row) => total + row.billDeductionAmount, 0)) / navigation.dolarExchangeRate).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2}) }}</p>
              </div>
              <p v-else style="font-size: large;">{{ display.currencySymbol }} {{ (item.billDeductionAmount / navigation.dolarExchangeRate).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2}) }}</p>
            </template>
            <template v-slot:item.expenseAmount="{ item }">
              <div v-if="item.localityName == 'GLOBAL'">
                <p style="font-size: x-large; font-weight: bold">{{ display.currencySymbol }} {{ ((Object.values(display.globalInformation).reduce((total, row) => total + row.expenseAmount, 0)) / navigation.dolarExchangeRate).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2}) }}</p>
              </div>
              <p v-else style="font-size: large;">{{ display.currencySymbol }} {{ (item.expenseAmount / navigation.dolarExchangeRate).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2}) }}</p>
            </template>
            <template v-slot:item.callcenterExpenseAmount="{ item }">
              <div v-if="item.localityName == 'GLOBAL'">
                <p style="font-size: x-large; font-weight: bold">{{ display.currencySymbol }} {{ ((Object.values(display.globalInformation).reduce((total, row) => total + row.callcenterExpenseAmount, 0)) / navigation.dolarExchangeRate).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2}) }}</p>
              </div>
              <p v-else style="font-size: large;">{{ display.currencySymbol }} {{ (item.callcenterExpenseAmount / navigation.dolarExchangeRate).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2}) }}</p>
            </template>
            <template v-slot:item.operationalExpenseAmount="{ item }">
              <div v-if="item.localityName == 'GLOBAL'">
                <p style="font-size: x-large; font-weight: bold">{{ display.currencySymbol }} {{ ((Object.values(display.globalInformation).reduce((total, row) => total + row.operationalExpenseAmount, 0)) / navigation.dolarExchangeRate).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2}) }}</p>
              </div>
              <p v-else style="font-size: large;">{{ display.currencySymbol }} {{ (item.operationalExpenseAmount / navigation.dolarExchangeRate).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2}) }}</p>
            </template>
            <template v-slot:item.total="{ item }">
              <div v-if="item.localityName == 'GLOBAL'">
                <p style="font-size: x-large; font-weight: bold">{{ display.currencySymbol }} {{ (Object.values(display.globalInformation).reduce((total, row) => total + (row.billAmount - row.billDeductionAmount - row.expenseAmount - row.productCostAmount - row.callcenterExpenseAmount - row.operationalExpenseAmount), 0)).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2}) }}</p>
              </div>
              <p v-else style="font-size: large;">{{ display.currencySymbol }} {{ ((item.billAmount - item.billDeductionAmount - item.expenseAmount - item.productCostAmount - item.callcenterExpenseAmount - item.operationalExpenseAmount) / navigation.dolarExchangeRate).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2}) }}</p>
            </template>
          </v-data-table-virtual>
          <br><br>
          <div style="width: 50%; display: flex;">
            <div style="width: 49%; margin-right: 1%;">
              <p style="font-size: x-large; font-weight: bold;">Utilidad neta:</p>
              <p style="font-size: x-large; font-weight: bold;">Gasto call center:</p>
              <p style="font-size: x-large; font-weight: bold;">Gasto operativo:</p>
              <br>
              <p style="font-size: xx-large; font-weight: bold;">GRAN TOTAL:</p>
            </div>
            <div style="width: 49%; margin-left: 1%;">
              <p style="font-size: x-large;">{{ display.currencySymbol }} {{ ((Object.values(display.globalInformation).reduce((total, row) => total + (row.billAmount - row.billDeductionAmount - row.expenseAmount - row.productCostAmount), 0)) / navigation.dolarExchangeRate).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2}) }}</p>
              <p style="font-size: x-large;">{{ display.currencySymbol }} {{ ((Object.values(display.globalInformation).reduce((total, row) => total + row.callcenterExpenseAmount, 0)) / navigation.dolarExchangeRate).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2}) }}</p>
              <p style="font-size: x-large;">{{ display.currencySymbol }} {{ ((Object.values(display.globalInformation).reduce((total, row) => total + row.operationalExpenseAmount, 0)) / navigation.dolarExchangeRate).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2}) }}</p>
              <br>
              <p style="font-size: xx-large; font-weight: bold;">{{ display.currencySymbol }} {{ ((Object.values(display.globalInformation).reduce((total, row) => total + (row.billAmount - row.billDeductionAmount - row.expenseAmount - row.productCostAmount - row.callcenterExpenseAmount - row.operationalExpenseAmount), 0)) / navigation.dolarExchangeRate).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2}) }}</p>
            </div>
            <v-icon @click="generateOperationDashboardReport()" size="30" color="success" style="margin-left: auto;">mdi-printer</v-icon>

          </div>
        </div>
      </div>
      <div v-else>
        <div v-for="(locality, localityKey) in display.information" v-bind:key="locality" style="margin: 25px 0px 40px 0px;">
          <div v-if="input.localityIDS.includes(parseInt(localityKey))" :style="`padding: 30px; border-radius: 10px; width: 100%; background-color: ${hexToRgba(locality.localityColor)};`">
            <div style="display: flex;">
              <h1>{{ locality.localityName }}</h1>
              <v-icon @click="generateOperationDashboardLocalityReport(localityKey)" size="30" color="success" style="margin-left: auto;">mdi-printer</v-icon>
            </div>
            <br><br>
            <div style="width: 100%; display: flex;">
              <div style="margin-right: 1%;">
                <v-data-table-virtual :headers="display.billPaymentTypeHeaders" :items="locality.billPaymentType">
                  <template v-slot:item.billPaymentTypeName="{ item }">
                    <div style="width: 200px;">
                      <p v-if="item.billPaymentTypeName == 'TOTAL'" style="font-size: large; font-weight: bold;">{{ item.billPaymentTypeName }}</p>
                      <p v-else style="font-size: large;">{{ item.billPaymentTypeName }}</p>
                    </div>
                  </template>
                  <template v-slot:item.billPaymentTypeAmount="{ item }">
                    <div style="width: 200px;">
                      <p v-if="item.billPaymentTypeName == 'TOTAL'" style="font-size: large; font-weight: bold;">{{ display.currencySymbol }} {{ (locality.billAmount / navigation.dolarExchangeRate).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2}) }}</p>
                      <p v-else style="font-size: large;">{{ display.currencySymbol }} {{ (item.billPaymentTypeAmount / navigation.dolarExchangeRate).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2}) }}</p>
                    </div>
                  </template>
                  <template v-slot:item.billPaymentTypeDeduction="{ item }">
                    <div style="width: 200px;">
                      <div v-if="item.billPaymentTypeName == 'TOTAL'">
                        <p style="font-size: large; font-weight: bold;">{{ display.currencySymbol }} {{ (locality.billDeductionAmount / navigation.dolarExchangeRate).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2}) }}</p>
                      </div>
                      <div v-else>
                        <p v-if="item.billPaymentTypeName == 'Tarjeta'" style="font-size: large;">{{ display.currencySymbol }} {{ ((item.billPaymentTypeAmount*0.1) / navigation.dolarExchangeRate).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2}) }}</p>
                        <p v-else-if="item.billPaymentTypeName == 'Página web'" style="font-size: large;">{{ display.currencySymbol }} {{ ((item.billPaymentTypeAmount*0.1) / navigation.dolarExchangeRate).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2}) }}</p>
                        <p v-else-if="item.billPaymentTypeName == 'Rappi'" style="font-size: large;">{{ display.currencySymbol }} {{ ((item.billPaymentTypeAmount*0.1) / navigation.dolarExchangeRate).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2}) }}</p>
                        <p v-else-if="item.billPaymentTypeName == 'Uber'" style="font-size: large;">{{ display.currencySymbol }} {{ ((item.billPaymentTypeAmount*0.18) / navigation.dolarExchangeRate).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2}) }}</p>
                        <p v-else style="font-size: large;">{{ display.currencySymbol }} 0</p>
                      </div>
                    </div>
                  </template>
                  <template v-slot:item.billPaymentTypeTotal="{ item }">
                    <div style="width: 200px;">
                      <div v-if="item.billPaymentTypeName == 'TOTAL'">
                        <p style="font-size: large; font-weight: bold;">{{ display.currencySymbol }} {{ ((locality.billAmount - locality.billDeductionAmount) / navigation.dolarExchangeRate).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2}) }}</p>
                      </div>
                      <div v-else>
                        <p v-if="item.billPaymentTypeName == 'Tarjeta'" style="font-size: large;">{{ display.currencySymbol }} {{ ((item.billPaymentTypeAmount - item.billPaymentTypeAmount*0.1) / navigation.dolarExchangeRate).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2}) }}</p>
                        <p v-else-if="item.billPaymentTypeName == 'Página web'" style="font-size: large;">{{ display.currencySymbol }} {{ ((item.billPaymentTypeAmount - item.billPaymentTypeAmount*0.1) / navigation.dolarExchangeRate).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2}) }}</p>
                        <p v-else-if="item.billPaymentTypeName == 'Rappi'" style="font-size: large;">{{ display.currencySymbol }} {{ ((item.billPaymentTypeAmount - item.billPaymentTypeAmount*0.1) / navigation.dolarExchangeRate).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2}) }}</p>
                        <p v-else-if="item.billPaymentTypeName == 'Uber'" style="font-size: large;">{{ display.currencySymbol }} {{ ((item.billPaymentTypeAmount - item.billPaymentTypeAmount*0.18) / navigation.dolarExchangeRate).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2}) }}</p>
                        <p v-else style="font-size: large;">{{ display.currencySymbol }} {{ (item.billPaymentTypeAmount / navigation.dolarExchangeRate).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2}) }}</p>
                      </div>
                    </div>
                  </template>
                </v-data-table-virtual>
              </div>
              <div style="margin-left: 1%;">
                <v-data-table-virtual :headers="display.resultHeaders" :items="locality.result">
                  <template v-slot:item>
                    <tr>
                      <td>
                        <div style="width: 200px;">
                          <p style="font-size: large;"><strong>VENTAS</strong></p>
                        </div>
                      </td>
                      <td>
                        <div style="width: 200px;">
                          <p style="font-size: large;">{{ display.currencySymbol }} {{ (locality.billAmount / navigation.dolarExchangeRate).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2}) }}</p>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div style="width: 200px;">
                          <p style="font-size: large;"><strong>UTILIDAD BRUTA</strong></p>
                        </div>
                      </td>
                      <td>
                        <div style="width: 200px;">
                          <p style="font-size: large;">{{ display.currencySymbol }} {{ ((locality.billAmount - locality.productCostAmount) / navigation.dolarExchangeRate).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2}) }}</p>                        
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div style="width: 200px;">
                          <p style="font-size: large;"><strong>DEDUCCIÓN</strong></p>
                        </div>
                      </td>
                      <td>
                        <div style="width: 200px;">
                          <p style="font-size: large;">{{ display.currencySymbol }} {{ (locality.billDeductionAmount / navigation.dolarExchangeRate).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2}) }}</p>                        
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div style="width: 200px;">
                          <p style="font-size: large;"><strong>GASTOS (LOCALIDAD)</strong></p>
                        </div>
                      </td>
                      <td>
                        <div style="width: 200px;">
                          <p style="font-size: large;">{{ display.currencySymbol }} {{ (locality.expenseAmount / navigation.dolarExchangeRate).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2}) }}</p>                        
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div style="width: 250px;">
                          <p style="font-size: large;"><strong>GASTOS (CALL CENTER)</strong></p>
                        </div>
                      </td>
                      <td>
                        <div style="width: 200px;">
                          <p style="font-size: large;">{{ display.currencySymbol }} {{ (locality.callcenterExpenseAmount / navigation.dolarExchangeRate).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2}) }}</p>                        
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div style="width: 200px;">
                          <p style="font-size: large;"><strong>GASTOS (OPERATIVOS)</strong></p>
                        </div>
                      </td>
                      <td>
                        <div style="width: 200px;">
                          <p style="font-size: large;">{{ display.currencySymbol }} {{ (locality.operationalExpenseAmount / navigation.dolarExchangeRate).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2}) }}</p>                       
                         </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div style="width: 200px;">
                          <p style="font-size: x-large;"><strong>TOTAL</strong></p>
                        </div>
                      </td>
                      <td>
                        <div style="width: 200px;">
                          <p style="font-size: x-large;"><strong>{{ display.currencySymbol }} {{ ((locality.billAmount - locality.billDeductionAmount - locality.expenseAmount - locality.productCostAmount - locality.callcenterExpenseAmount - locality.operationalExpenseAmount) / navigation.dolarExchangeRate).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2}) }}</strong></p>                   
                        </div>
                      </td>
                    </tr>
                  </template>
                </v-data-table-virtual>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br>
    </div>
  </div>
</template>

<style scoped>
</style>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'OperationDashboardView',
  inject: ['$generalFunctions'],

  
  data: () => ({
    input: 
    {
      localityIDS: [],
      allLocalitySelected: false,
      globalReportSelected: false,
      startDate: null,
      endDate: null
    },

    navigation: 
    {
      startDatePickerIsVisible: false,
      endDatePickerIsVisible: false,
      dolarExchangeRate: 1
    },

    loader: 
    {
      selectLocalityIDAndLocalityName: false,
      selectInformation: false,
    },

    display:
    {
      currencySymbol: '₡',
      localityOptions: [],
      startDateFormatted: null,
      endDateFormatted: null,
      information: null,
      globalInformation: null,
      billPaymentTypeHeaders: 
      [
        {key: 'billPaymentTypeName', title: 'MÉTODO DE PAGO'},
        {key: 'billPaymentTypeAmount', title: 'MONTO'},
        {key: 'billPaymentTypeDeduction', title: 'DEDUCCIÓN'},
        {key: 'billPaymentTypeTotal', title: 'TOTAL'}
      ],

      resultHeaders:
      [
        {key: 'billAmount', title: null},
        {key: 'billAmountWithoutCost', title: null}
      ],

      globalReportHeaders:
      [
        {key: 'localityName', title: 'LOCALIDAD'},
        {key: 'billAmount', title: 'VENTAS'},
        {key: 'billAmountWithoutCost', title: 'UTILIDAD BRUTA'},
        {key: 'billDeductionAmount', title: 'DEDUCCIÓN'},
        {key: 'expenseAmount', title: 'GASTOS (LOCALIDAD)'},
        {key: 'callcenterExpenseAmount', title: 'GASTOS (CALL CENTER)'},
        {key: 'operationalExpenseAmount', title: 'GASTOS (OPERATIVOS)'},
        {key: 'total', title: 'UTILIDAD NETA'}
      ],

    },

    accessCredential: {},
    
    localStorage: 
    {
      agentID: null
    }

  }),

  watch: {
    'input.startDate': async function() {
      this.display.startDateFormatted = new Date(this.input.startDate).toLocaleDateString('en-GB');
      this.navigation.startDatePickerIsVisible = false;
      this.display.information = null;
    },
    'input.endDate': async function() {
      this.display.endDateFormatted = new Date(this.input.endDate).toLocaleDateString('en-GB');
      this.navigation.endDatePickerIsVisible = false;
      this.display.information = null;
    },
    'input.localityIDS': async function (){
      this.input.allLocalitySelected = this.input.localityIDS.length == this.display.localityOptions.length;
    },
    'display.currencySymbol': async function(){
      if (this.display.currencySymbol == '₡'){
        this.navigation.dolarExchangeRate = 1;
      } else {
        this.navigation.dolarExchangeRate = 511;
      }
    },
  },

  methods: {

    hexToRgba(hex) {
      hex = hex.replace('#', '');
      let r = parseInt(hex.substring(0, 2), 16);
      let g = parseInt(hex.substring(2, 4), 16);
      let b = parseInt(hex.substring(4, 6), 16);
      return `rgba(${r}, ${g}, ${b}, ${0.2})`;
    },

    async generateOperationDashboardLocalityReport(localityID){
      const generateOperationDashboardLocalityReportRequestQuery = 
      {
        'localityID': localityID,
        'startDate': this.input.startDate,
        'endDate': this.input.endDate
      };
      const generateOperationDashboardLocalityReportResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/dashboard/operation/functions/generateOperationDashboardLocalityReport', generateOperationDashboardLocalityReportRequestQuery);
      if (generateOperationDashboardLocalityReportResult.success){
        const printablePDFExcelDocumentDialogRequestQuery = 
          {
            'printablePDFDocumentFile': generateOperationDashboardLocalityReportResult.result.documentFile.data,
            'printablePDFDocumentName': generateOperationDashboardLocalityReportResult.result.documentName,
            'printableExcelDocumentFile': null,
            'printableExcelDocumentName': null
          };
          this.$root.printablePDFExcelDocumentDialog.openPrintablePDFExcelDocumentDialog(printablePDFExcelDocumentDialogRequestQuery);
          this.$root.notificationDialog.showNotificationDialog({
            'notificationDialogTitle': 'ÉXITO',
            'notificationDialogBody': 'El reporte se ha generado exitosamente',
            'notificationDialogColor': 'success',
            'notificationDialogIsPersistent': false
          });      
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al generar el reporte, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },

    async generateOperationDashboardReport(){
      const generateOperationDashboardReportRequestQuery = 
      {
        'startDate': this.input.startDate,
        'endDate': this.input.endDate
      };
      const generateOperationDashboardReportResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/dashboard/operation/functions/generateOperationDashboardReport', generateOperationDashboardReportRequestQuery);
      if (generateOperationDashboardReportResult.success){
        const printablePDFExcelDocumentDialogRequestQuery = 
          {
            'printablePDFDocumentFile': generateOperationDashboardReportResult.result.documentFile.data,
            'printablePDFDocumentName': generateOperationDashboardReportResult.result.documentName,
            'printableExcelDocumentFile': null,
            'printableExcelDocumentName': null
          };
          this.$root.printablePDFExcelDocumentDialog.openPrintablePDFExcelDocumentDialog(printablePDFExcelDocumentDialogRequestQuery);
          this.$root.notificationDialog.showNotificationDialog({
            'notificationDialogTitle': 'ÉXITO',
            'notificationDialogBody': 'El reporte se ha generado exitosamente',
            'notificationDialogColor': 'success',
            'notificationDialogIsPersistent': false
          });      
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al generar el reporte, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },

    async selectInformation(){
      this.loader.selectInformation = true;
      this.display.information = null;
      const selectInformationRequestQuery =
      {
        'localityIDS': this.display.localityOptions.map(localityOption => localityOption.localityID),
        'startDate': this.input.startDate,
        'endDate': this.input.endDate
      };
      const selectInformationResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/dashboard/operation/functions/selectInformation', selectInformationRequestQuery);
      if (selectInformationResult.success){
        this.display.information = selectInformationResult.result;
        this.display.globalInformation = JSON.parse(JSON.stringify(this.display.information));
        this.display.globalInformation['1000'] = {'localityName': 'GLOBAL', 'billAmount': 0, 'billDeductionAmount': 0, 'productCostAmount': 0, 'expenseAmount': 0, 'callcenterExpenseAmount': 0, 'operationalExpenseAmount': 0};
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar la información del dashboard, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
      this.loader.selectInformation = false;
    },

    async selectLocalityIDAndLocalityName(){
      this.loader.selectLocalityIDAndLocalityName = true;
      const selectLocalityIDAndLocalityNameResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/locality/select/localityIDAndLocalityName');
      if (selectLocalityIDAndLocalityNameResult.success){
        this.display.localityOptions = selectLocalityIDAndLocalityNameResult.result;
        this.loader.selectLocalityIDAndLocalityName = false;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar las localidades, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },

    async selectAllLocality(){
      if (this.input.allLocalitySelected == false){
        this.input.localityIDS = this.display.localityOptions.map(localityOption => localityOption.localityID);
      } else {
        this.input.localityIDS.length = 0;
      }
    },

    async selectGlobalReport(){
      this.input.allLocalitySelected = true;
      this.input.localityIDS = this.display.localityOptions.map(localityOption => localityOption.localityID);
    },

    async setDefaultValues(){
      this.input.localityIDS.length = 0;
      this.input.allLocalitySelected = false;
      this.input.globalReportSelected = false;
      const startDate = new Date();
      startDate.setMonth(0);
      startDate.setDate(1);
      this.input.startDate = startDate;
      this.input.endDate = new Date();
      this.navigation.startDatePickerIsVisible = false;
      this.navigation.endDatePickerIsVisible = false;
      this.loader.selectLocalityIDAndLocalityName = false;
      this.loader.selectInformation = false;
      this.display.startDateFormatted = null;
      this.display.endDateFormatted = null;
      this.display.information = null;
    },

    async getLocalStorageData(){
      this.localStorage.agentID = localStorage.getItem('agentID');
      this.accessCredential = await this.$generalFunctions.default.methods.validateCredentialAccess(this.localStorage.agentID);
    }
  
  },

  async mounted(){
    await this.getLocalStorageData();
    await this.setDefaultValues();
    await this.selectLocalityIDAndLocalityName();
    await this.selectAllLocality();
  }

});

</script>