<template>
  <div style="margin: 2% 3% 0% 3%;">
    <h1>Contactos</h1>
    <br>
    <v-tabs v-model="navigation.selectedTab" color="info">
      <v-tab value="Todos">TODOS</v-tab>
      <v-tab value="Persona">PERSONAS</v-tab>
      <v-tab value="Empresa">EMPRESAS</v-tab>
      <v-tab value="Otro">OTROS</v-tab>
    </v-tabs>
    <br><br>
    <div v-if="loader.selectContactFromContactsView" style="text-align: center;">
      <v-progress-circular color="info" indeterminate></v-progress-circular>
    </div>
    <div v-else>
      <div style="display: flex;">
        <v-text-field v-model="input.searchedContact" prepend-inner-icon="mdi-magnify" label="Buscar contacto" variant="solo-filled"></v-text-field>
        <v-switch v-model="input.showInactiveContact" color="info" label="Mostrar contactos inactivos" hide-details style="margin-left: 40px; margin-bottom: 20px;"></v-switch>
        <v-btn @click="openInsertContactDialog()" dark height="38" color="success" style="margin-left: 40px; margin-top: 10px;">
        <h3>CREAR CONTACTO</h3>
        <v-icon style="margin-left: 10px;" dark right>mdi-plus-circle-outline</v-icon>
      </v-btn>
      </div>
      
      <br><br>
      <v-card elevation="3" style="padding: 20px; background-color: #f5f5f5; max-height: 700px; overflow-y: auto;">
        <v-data-table-virtual :headers="display.contactHeaders" :items="display.contactRows">
          <template v-slot:item="row">
            <tr @click="openContactDrawer(row.item.contactID)" :style="`cursor: pointer; ${ row.item.contactLastSale ? ((((new Date(row.item.contactLastSale) - new Date()) / (1000 * 60 * 60 * 24)) > 30) ? 'background-color: #e08484;' : 'background-color: #a7d4a8;') : 'background-color: #cfcfcf'}`">
              <td>
                <div style="display: flex; width: 400px;">
                  <v-tooltip :text="row.item.contactCreditAmount ? 'Contacto a crédito' : 'Contacto a contado'" location="right">
                    <template v-slot:activator="{ props }">
                      <v-icon v-bind="props" :color="row.item.contactCreditAmount ? 'error' : 'success'" style="margin-right: 10px;">{{ row.item.contactCreditAmount ? 'mdi-cash-clock' : 'mdi-cash' }}</v-icon>
                    </template>
                  </v-tooltip>  
                  <p style="margin-right: 10px;">{{ row.item.contactName }}</p>
                  <v-rating hover :length="5" :size="20" :model-value="row.item.contactStarRating" active-color="white" readonly></v-rating>
                </div>          
              </td>
              <td>
                {{ row.item.contactSSN }}
              </td>
              <td>
                <v-chip :color="row.item.agentColor" variant="flat">
                  <p :style="{color: row.item.agentFontColor}">{{ row.item.agentName }}</p>
                </v-chip>
              </td>
              <td>
                {{ row.item.contactLastSale ? $generalFunctions.default.methods.parseDatetimeToDDMMYYYHHMMSS(row.item.contactLastSale) : 'Sin ventas registradas'}}
              </td>
              <td v-if="navigation.selectedTab == 'Todos'">
                {{ row.item.contactType }}
              </td>
              <td>
                <v-chip :color="row.item.contactIsActive == 1 ? 'success' : 'error'" variant="flat">
                  <p style="color: white;">{{ row.item.contactIsActive == 1 ? 'Activo' : 'Inactivo' }}</p>
                </v-chip>
              </td>
            </tr>
          </template>
        </v-data-table-virtual>
      </v-card>
    </div>
    <br><br>
  </div>
  <v-navigation-drawer v-model="navigation.contactDrawerIsVisible" absolute temporary class="bg-info" location="right" style="min-width: 25%;">
    <div v-if="loader.selectContactFromContactDrawer" style="display: flex; justify-content: center; align-items: center; height: 100%;">
      <v-progress-circular color="white" indeterminate></v-progress-circular>
    </div>
    <div v-else style="padding: 30px;">
      <div v-if="display.contactDrawer">
        <div style="text-align: center; position: relative;">
          <div style="position: absolute; top: 10px; left: 10px; display: flex; flex-direction: column; align-items: center;">
            <v-tooltip :text="display.contactDrawer.contactCreditAmount ? 'Contacto a crédito' : 'Contacto a contado'" location="right">
              <template v-slot:activator="{ props }">
                <v-icon v-bind="props" :color="display.contactDrawer.contactCreditAmount ? 'error' : 'success'" size="30" style="cursor: pointer;">{{ display.contactDrawer.contactCreditAmount ? 'mdi-cash-clock' : 'mdi-cash' }}</v-icon>
              </template>
            </v-tooltip> 
            <v-tooltip text="Perfil del contacto" location="right">
              <template v-slot:activator="{ props }">
                <v-icon v-bind="props" @click="navigateTo(`contact/${display.contactDrawer.contactID}`)" color="white" size="30" style="cursor: pointer; margin-top: 10px;">mdi-information</v-icon>
              </template>
            </v-tooltip> 
            <v-tooltip text="Actualizar contacto" location="right">
              <template v-slot:activator="{ props }">
                <v-icon v-bind="props" v-if="accessCredential['/contacts/contactDialog/updateContact']" @click="openContactDialog()" color="white" size="30" style="cursor: pointer; margin-top: 10px;">mdi-pencil</v-icon>
              </template>
            </v-tooltip> 
            <v-tooltip text="Generar proforma" location="right">
              <template v-slot:activator="{ props }">
                <v-icon v-bind="props" v-if="accessCredential['/proformas/insertProformaDialog']" @click="openInsertProformaDialog()" color="white" size="30" style="cursor: pointer; margin-top: 10px;">mdi-invoice-plus</v-icon>
              </template>
            </v-tooltip> 
          </div>
          <img :src="display.contactDrawer.contactImage || assets.userPlaceholderImage" style="width: 200px; height: 200px; border-radius: 50%; object-fit: cover;">
          <br><br>
          <h2>{{ display.contactDrawer.contactName }}</h2>
          <h3>{{ display.contactDrawer.contactSSN }}</h3>
          <v-rating hover :length="5" :size="20" :model-value="display.contactDrawer.contactStarRating" active-color="white" readonly style="margin-top: 10px;"></v-rating>
        </div>
        <br>
        <v-card elevation="3" style="padding: 20px 20px 20px 20px; background-color: #ededed;">
          <div style="text-align: center;">
            <div style="display: inline-flex;">
              <v-tooltip text="Frecuencia media de compra" location="top">
                <template v-slot:activator="{ props }">
                  <v-chip v-bind="props" color="purple" label style="cursor: pointer;">
                    <v-icon size="30" icon="mdi-calendar-multiselect" start></v-icon>
                    <p style="font-size: large;">{{ display.contactDrawer.contactInterval ? `${display.contactDrawer.contactInterval.days} días` : 'Sin intervalo'}}</p>
                  </v-chip>
                </template>
              </v-tooltip>
              <v-tooltip text="Ticket promedio" location="top">
                <template v-slot:activator="{ props }">
                  <v-chip style="margin-left: 10px; cursor: pointer;" v-bind="props" color="indigo" label>
                    <v-icon size="30" icon="mdi-cash-clock" start></v-icon>
                    <p style="font-size: large;">{{ display.contactDrawer.contactAverageTicket ? `₡ ${display.contactDrawer.contactAverageTicket.toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2})}` : 'Sin ventas' }}</p>
                  </v-chip>
                </template>
              </v-tooltip>
            </div>
            <br><br>
            <div style="display: inline-flex;">
              <v-tooltip text="Cantidad de paquetes vendidos este mes" location="top">
                <template v-slot:activator="{ props }">
                  <v-chip v-bind="props" color="info" label style="cursor: pointer;">
                    <v-icon size="30" icon="mdi-package" start></v-icon>
                    <p style="font-size: large;">{{ display.contactDrawer.monthBillCount }}</p>
                  </v-chip>
                </template>
              </v-tooltip>
              <v-tooltip text="Cantidad vendida este mes" location="top">
                <template v-slot:activator="{ props }">
                  <v-chip style="margin-left: 10px; cursor: pointer;" v-bind="props" color="success" label>
                    <v-icon size="30" icon="mdi-cash" start></v-icon>
                    <p style="font-size: large;">₡{{ display.contactDrawer.monthBillAmount.toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2}) }}</p>
                  </v-chip>
                </template>
              </v-tooltip>
            </div>
            <br><br>
            <div style="display: inline-flex;">
              <v-tooltip text="Cantidad de paquetes vendidos este año" location="top">
                <template v-slot:activator="{ props }">
                  <v-chip v-bind="props" color="info" label>
                    <v-icon size="30" icon="mdi-package" start style="cursor: pointer;"></v-icon>
                    <p style="font-size: large;">{{ display.contactDrawer.yearBillCount }}</p>
                  </v-chip>
                </template>
              </v-tooltip>
              <v-tooltip text="Cantidad vendida este año" location="top">
                <template v-slot:activator="{ props }">
                  <v-chip style="margin-left: 10px; cursor: pointer;" v-bind="props" color="success" label>
                    <v-icon size="30" icon="mdi-cash-multiple" start></v-icon>
                    <p style="font-size: large;">₡{{ display.contactDrawer.yearBillAmount.toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2}) }}</p>
                  </v-chip>
                </template>
              </v-tooltip>
            </div>
            <div v-if="display.contactDrawer.contactTotalDebtAmount > 0">
              <br>
              <div style="text-align: center;">
                <v-tooltip text="Deuda" location="top">
                  <template v-slot:activator="{ props }">
                    <v-chip style="cursor: pointer;" v-bind="props" color="error" label>
                      <v-icon size="30" icon="mdi-cash-clock" start></v-icon>
                      <p style="font-size: large;">₡{{ display.contactDrawer.contactTotalDebtAmount.toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2}) }}</p>
                    </v-chip>
                  </template>
                </v-tooltip>
              </div>
            </div>
          </div>
        </v-card>
        <br>
        <v-card elevation="3" style="padding: 20px 20px 20px 20px; background-color: #ededed;">
          <div v-if="display.contactDrawer.contactCommercialName" style="display: flex; padding: 5px;">
            <v-icon color="info" center>mdi-store</v-icon>
            <p style="margin-left: 10px;">{{ display.contactDrawer.contactCommercialName }}</p>
          </div>
          <div v-if="display.contactDrawer.contactPhoneNumber" style="display: flex; padding: 5px;">
            <v-icon color="info" center>mdi-phone</v-icon>
            <p style="margin-left: 10px;">{{ display.contactDrawer.contactPhoneNumber }}</p>
          </div>
          <div v-if="display.contactDrawer.contactEmail" style="display: flex; padding: 5px;">
            <v-icon color="info" center>mdi-email</v-icon>
            <p style="margin-left: 10px;">{{ display.contactDrawer.contactEmail }}</p>
          </div>
          <div v-if="display.contactDrawer.contactWebPage" style="display: flex; padding: 5px;">
            <v-icon color="info" center>mdi-web</v-icon>
            <a :href="display.contactDrawer.contactWebPage.startsWith('http') ? display.contactDrawer.contactWebPage : `http://${display.contactDrawer.contactWebPage}`" target="_blank" style="margin-left: 10px; text-decoration: none; color: inherit;">
              {{ display.contactDrawer.contactWebPage }}
            </a>
          </div>
          <br v-if="display.contactDrawer.contactCountry || display.contactDrawer.contactProvince || display.contactDrawer.contactCanton || display.contactDrawer.contactDistrict || display.contactDrawer.contactLocationNote">
          <div v-if="display.contactDrawer.contactCountry" style="display: flex; padding: 5px;">
            <v-icon color="info" center>mdi-earth</v-icon>
            <p style="margin-left: 10px;">{{ display.contactDrawer.contactCountry }}</p>
          </div>
          <div v-if="display.contactDrawer.contactProvince || display.contactDrawer.contactCanton || display.contactDrawer.contactDistrict" style="display: flex; padding: 5px;">
            <v-icon color="info" center>mdi-map-marker</v-icon>
            <p style="margin-left: 10px;">{{ [display.contactDrawer.contactProvince, display.contactDrawer.contactCanton, display.contactDrawer.contactDistrict].filter(Boolean).join(', ') }}</p>
          </div>
        </v-card>
        <br>
        <v-textarea @click:append-inner="updateContactNote()" v-model="display.contactDrawer.contactNote" label="Nota del contacto" rows="4" append-inner-icon="mdi-content-save" variant="solo-filled"></v-textarea>
      </div>
    </div>
  </v-navigation-drawer>
</template>

<style scoped>
</style>


<script>
import { defineComponent } from 'vue';
import { viewMethodCaller } from '../viewMethodCaller.js';

export default defineComponent({
  name: 'ContactsView',

  inject: ['$generalFunctions'],

  data: () => ({
    input: 
    {
      searchedContact: null,
      showInactiveContact: false
    },

    display: 
    {
      contactHeaders:
      [
        {key: 'contactName', title: 'CONTACTO'},
        {key: 'contactSSN', title: 'DOCUMENTO DE IDENTIDAD'},
        {key: 'agentName', title: 'AGENTE ENCARGADO'},
        {key: 'contactLastSale', title: 'ÚLTIMA COMPRA'},
        {key: 'contactIsActive', title: 'ESTADO'}
      ],
      contactOriginalRows: [],
      contactRows: [],
      contactDrawer: null
    },

    navigation: 
    {
      selectedTab: "Todos",
      contactDrawerIsVisible: false
    },

    loader:
    {
      selectContactFromContactsView: false,
      selectContactFromContactDrawer: false
    },

    localStorage: 
    {
      agentID: null
    },

    assets: 
    {
      userPlaceholderImage: null
    },

    accessCredential: {}
  }),

  watch: {
    async 'navigation.selectedTab'(){
      this.display.contactHeaders = [
        {key: 'contactSSN', title: 'DOCUMENTO DE IDENTIDAD'},
        {key: 'contactName', title: 'NOMBRE'},
        {key: 'agentName', title: 'AGENTE ENCARGADO'},
        {key: 'contactLastSale', title: 'ÚLTIMA COMPRA'},
        ...(this.navigation.selectedTab == 'Todos' ? [{key: 'contactType', title: 'TIPO'}] : []),
        {key: 'contactIsActive', title: 'ESTADO'}
      ];
      this.input.showInactiveContact = false;
      this.input.searchedContact = null;
      await this.selectContactFromContactsView();
    },

    'input.searchedContact'(){
      if (this.input.searchedContact){
        const searchTerm = this.input.searchedContact.toLowerCase();
        this.display.contactRows = this.display.contactOriginalRows.filter(contact => {
          const contactName = contact.contactName.toLowerCase();
          return contactName.includes(searchTerm);
        });
      } else {
        this.display.contactRows = JSON.parse(JSON.stringify(this.display.contactOriginalRows));
      }
    },

    async 'input.showInactiveContact'(){
      this.input.searchedContact = null;
      await this.selectContactFromContactsView();
    }
  },

  methods: {
    async updateContactNote(){
      this.loader.selectContactFromContactDrawer = true;
      const updateContactNoteRequestQuery =
      {
        'contactID': this.display.contactDrawer.contactID,
        'contactNote': this.display.contactDrawer.contactNote
      };
      const updateContactNoteResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/contact/functions/updateContactNote', updateContactNoteRequestQuery);
      if (updateContactNoteResult.success){
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ÉXITO',
          'notificationDialogBody': 'Nota del cliente actualizada exitosamente',
          'notificationDialogColor': 'success',
          'notificationDialogIsPersistent': false
        });
        await this.openContactDrawer(this.display.contactDrawer.contactID);
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al actualizar la nota del contacto, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
      this.loader.selectContactFromContactDrawer = false;
    },

    async openContactDrawer(contactID){
      this.navigation.contactDrawerIsVisible = true;
      this.loader.selectContactFromContactDrawer = true;
      const selectContactFromContactDrawerRequestQuery =
      {
        'contactID': contactID
      };
      const selectContactFromContactDrawerResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/contact/functions/selectContactFromContactDrawer', selectContactFromContactDrawerRequestQuery);
      if (selectContactFromContactDrawerResult.success){
        this.display.contactDrawer = selectContactFromContactDrawerResult.result;
        this.display.contactDrawer.contactImage = this.display.contactDrawer.contactImage ? `data:image/jpeg;base64,${this.display.contactDrawer.contactImage}` : null;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar la información del contacto, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
        this.navigation.contactDrawerIsVisible = false;
      }
      this.loader.selectContactFromContactDrawer = false;
    },

    async selectContactFromContactsView(){
      this.loader.selectContactFromContactsView = true;
      const selectContactFromContactsViewRequestQuery =
      {
        'contactType': this.navigation.selectedTab,
        'showInactiveContact': this.input.showInactiveContact
      };
      const selectContactFromContactsViewResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/contact/functions/selectContactFromContactsView', selectContactFromContactsViewRequestQuery);
      if (selectContactFromContactsViewResult.success){
        this.display.contactOriginalRows = selectContactFromContactsViewResult.result;
        this.display.contactRows = selectContactFromContactsViewResult.result;
        this.navigation.contactDrawerIsVisible = false;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar la lista de contactos, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
      this.loader.selectContactFromContactsView = false;
    },

    async openContactDialog(){
      this.$root.contactDialog.openContactDialog(this.display.contactDrawer.contactID);
    },

    async openInsertContactDialog(){
      this.$root.contactDialog.openContactDialog(null);
    },

    async openInsertProformaDialog(){
      this.$root.insertProformaDialog.openInsertProformaDialog(this.display.contactDrawer.contactID);
    },

    navigateTo(route){
      this.$generalFunctions.default.methods.navigateTo(route);
    },

    async getLocalStorageData(){
      this.assets.userPlaceholderImage = this.$generalFunctions.default.data.userPlaceholderImage;
      this.localStorage.agentID = localStorage.getItem('agentID');
      this.accessCredential = await this.$generalFunctions.default.methods.validateCredentialAccess(this.localStorage.agentID);
    },

    async listenToViewMethodCaller(){
      viewMethodCaller.on('ContactsView/methods/selectContactFromContactsView()', this.selectContactFromContactsView);
    },
  },

  async mounted(){
    await this.selectContactFromContactsView();
    await this.getLocalStorageData();
    await this.listenToViewMethodCaller();
  }

});
</script>
